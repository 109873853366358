<template>
  <v-app>
    <p class="error" v-if="error">{{ error.message }}</p>
    <validation-observer ref="form" v-slot="{ invalid }">
      <form @submit.prevent="fetchAssessmentInstances" class="d-flex">
        <validation-provider
          rules="uuid"
          name="Assessment Instance ID"
          v-slot="{ errors }"
        >
          <v-text-field
            :error-messages="errors"
            v-model="assessment_instance_id"
            prepend-icon="mdi-magnify"
            label="Assessment Instance ID"
            dense
            clearable
          />
        </validation-provider>
        <validation-provider
          rules="uuid"
          name="Assessment Type ID"
          v-slot="{ errors }"
        >
          <v-text-field
            :error-messages="errors"
            v-model="assessment_type_id"
            prepend-icon="mdi-magnify"
            label="Assessment Type ID"
            dense
            clearable
          />
        </validation-provider>
        <validation-provider
          rules="alpha_dash|min:3"
          name="Assessment Type"
          v-slot="{ errors }"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :on="on"
                v-bind="attrs"
                :error-messages="errors"
                v-model="assessment_type_name"
                prepend-icon="mdi-magnify"
                label="Assessment Type"
                dense
                clearable
              />
            </template>
            <span>Search</span>
          </v-tooltip>
        </validation-provider>
        <v-btn
          :disabled="invalid || isBusy"
          color="primary"
          target="_blank"
          type="submit"
          class="ml-auto mr-2"
        >
          <v-icon small>mdi-filter</v-icon>
          Search Assessment Instances
        </v-btn>
        <v-btn
          color="primary"
          type="reset"
          :disabled="isBusy"
          @click.prevent="clearFilter"
          class="mr-2"
        >
          <v-icon small>mdi-autorenew</v-icon>
          Clear Filters
        </v-btn>
        <v-btn
          color="teal"
          class="white--text"
          @click="addNew"
        ><fa icon="plus" class="mr-1" /> Add New</v-btn>
      </form>
    </validation-observer>

    <v-card class='mb-6'>
      <v-data-table
        fixed-header
        height="68vh"
        loading-text='Loading assessment instances ...'
        no-data-text='No assessment instances found.'
        :headers='headers'
        :items="assessmentInstances"
        :items-per-page='100'
        :loading="isBusy"
        :header-props="{ sortIcon: 'mdi-chevron-down' }"
        :footer-props="{ 'disable-items-per-page': true }"
      >

        <template v-slot:[`item.earliest_sitting_started_at`]="{ item }">
          <span>{{ item.earliest_sitting_started_at | dateString }}</span>
        </template>
        <template v-slot:[`item.latest_sitting_started_at`]="{ item }">
          <span>{{ item.latest_sitting_started_at | dateString }}</span>
        </template>
        <template v-slot:[`item.sitting_started_at`]="{ item }">
          <span>{{ item.sitting_started_at | dateString }}</span>
        </template>
        <template v-slot:[`item.retired_on`]="{ item }">
          <span>{{ item.retired_on | dateString }}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-switch
            inset
            v-model="item.status"
            @change="toggleStatus(item)"
            :label="getLabel(item)"
          />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions d-flex mb-2">
            <div class="ml-auto mr-2">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    color="teal"
                    v-bind="attrs"
                    v-on="on"
                    small
                    :to="{
                      path: `/assessment-instances/${item.assessment_instance_id}/items`,
                    }"
                  >
                    <v-icon small>mdi-view-list-outline</v-icon>
                  </v-btn>
                </template>
                <span>View Items</span>
              </v-tooltip>
            </div>
            <div>
              <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  color="orange"
                  v-bind="attrs"
                  v-on="on"
                  small
                  :to="{
                    path: '/sittings',
                    query: {
                      assessment_instance_id: item.assessment_instance_id,
                    },
                  }"
                >
                  <v-icon small>mdi-school</v-icon>
                </v-btn>
              </template>
              <span>View all Sittings for this Assessment Instance</span>
              </v-tooltip>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";

const updateQuery = (_this, propertyName, value) => {
  if (_this.$route.query[propertyName] === value) return

  const query = { ... _this.$route.query }
  if (!value) delete query[propertyName]
  else query[propertyName] = value
  _this.$router.push({ name: "AssessmentInstances", params: _this.$route.params, query })
}

export default {
  name: "AssessmentInstancesList",
  data: () => ({
    assessment_instance_id: '',
    assessment_type_id: '',
    assessment_type_name: '',
  }),
  computed: {
    ...mapGetters('assessmentInstances', [
      "assessmentInstances",
      "headers",
      "pagination",
      "isBusy",
      "error",
      "filters",
    ]),
  },
  methods: {
    fetchAssessmentInstances() {
      this.$store.dispatch("assessmentInstances/fetchAssessmentInstances");
    },
    clearFilter() {
      if (Object.keys(this.$route.query).length > 0) this.$router.push({ name: "AssessmentInstances", params: this.$route.params, query: {} })
      this.$store.dispatch('assessmentInstances/fetchAssessmentInstances')
    },
    addNew() {
      this.$router.push('/assessment-instances/new');
    },
    async toggleStatus(assessmentInstance) {
      const { status } = assessmentInstance;
      const success = await this.$store.dispatch('assessmentInstances/toggleStatus', assessmentInstance);
      assessmentInstance.status = success ? status : !status;
    },
    getLabel(assessmentInstance) {
      return assessmentInstance.status ? 'Active' : 'Inactive'
    },
  },
  watch: {
    assessment_instance_id(value) { updateQuery(this, 'assessment_instance_id', value) },
    assessment_type_id(value) { updateQuery(this, 'assessment_type_id', value) },
    assessment_type_name(value) { updateQuery(this, 'assessment_type_name', value) },
    $route: {
      handler() {
        this.assessment_instance_id = this.$route.query.assessment_instance_id
        this.assessment_type_id = this.$route.query.assessment_type_id
        this.assessment_type_name = this.$route.query.assessment_type_name
        this.$store.commit('assessmentInstances/SET_FILTERS', {
          assessment_instance_id: this.assessment_instance_id,
          assessment_type_id: this.assessment_type_id,
          assessment_type_name: this.assessment_type_name,
        })
        this.$store.dispatch('assessmentInstances/fetchAssessmentInstances')
      },
      immediate: true
    }
  },
  beforeMount() {
    const filters = {
      assessment_instance_id: this.$route.query.assessment_instance_id,
      assessment_type_id: this.$route.query.assessment_type_id,
      assessment_type_name: this.$route.query.assessment_type_name,
    }
    this.$store.commit('assessmentInstances/SET_FILTERS', filters)
    this.$store.dispatch('assessmentInstances/fetchAssessmentInstances')
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
  .v-btn {
    font-family: "BritishCouncilSansW15-Headline", sans-serif;
    font-size: 12px;
    text-transform: none;
    letter-spacing: normal;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.15);
    &.v-btn--disabled.v-btn--has-bg{
      background-color: #220a5a !important;
    }
  }
  .v-text-field .v-label {
    z-index: 100;
  }
  .v-data-table-header th {
    text-align: left;
    color: white !important;
    background-color: #220a5a !important;
    &:first-child {
      border-top-left-radius: 4px;
      padding-left: 34px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
    &.active,
    & .v-data-table-header__icon {
      color: #ffe300 !important;
    }
    &.sortable {
      white-space: nowrap;
    }
  }
  .v-data-table tbody tr {
    &.expired {
      opacity: 0.3;
      background: #fee;
      cursor: not-allowed;
    }
    & td {
      padding-top: 18px !important;
      padding-bottom: 18px !important;

      &:first-child {
        padding-left: 34px;
        background-color: inherit;
      }
      &:last-child {
        padding-right: 34px;
      }
    }
  }
  .v-data-footer__select {
    visibility: hidden;
  }
}

.actions {
  margin-right: -20px;
}
</style>